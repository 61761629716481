<!--
 * @Date: 2022-02-23 17:53:11
 * @LastEditTime: 2022-08-12 17:31:09
 * @Description: 
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\user\accountSettings\index.vue
-->
<template>
  <div>
    <!-- <van-cell title="头像" class="mt20">
      <template #right-icon>
        <default-image :url="user && user.avatar" is-avatar round width="35" height="35" />
      </template>
    </van-cell> -->
    <van-cell title="昵称" :value="user.name || user.phoneNum" />
    <van-cell title="手机号码" :value="user.phoneNum" />
    <van-cell title="修改密码" is-link to="/updatePwd" />
    <!-- <van-cell title="微信" value="绑定" /> -->
    <div class="exit mt20">
      <van-cell title="退出登录" @click="loginOut" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "AccountSettings",
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    loginOut() {
      this.$store.commit("user/setName", null)
      this.$store.commit("user/setPhoneNum", null)
      this.$store.commit("user/setToken", null)
      this.$store.commit("user/setRefreshToken", null)
      this.$router.replace("/")
    }
  }
}
</script>
<style scoped lang="less">
.exit {
  width: 100%;
  height: 106px;
  line-height: 106px;
  text-align: center;
  color: #dc3030;
  font-size: 30px;
  background: #ffffff;
}
</style>
